<template>
  <div>
    <avue-form ref="form"
               v-if="form.intermediaryState === 3"
               v-model="form"
               v-loading="loading"
               :option="formOption">
      <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
      <template slot="partyBName"><div>{{ form.partyBName }}</div></template>
      <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
      <template slot="houseName"><div>{{ form.houseName }}</div></template>
      <template slot="houseCode"><div>{{ form.houseCode }}</div></template>
      <template slot="businessType"><div>{{ form.businessType === 1 ? '二手房交易' : form.businessType === 2 ? '房屋租赁' : '/' }}</div></template>
      <template slot="county"><div>{{ form.county }}·{{ form.business }}·{{ form.buildName }}</div></template>
      <template slot="rentalMode"><div>{{ form.rentalMode === 1 ? '整租' : form.rentalMode === 2 ? '合租' : '/' }}</div></template>
      <template slot="bedroom"><div>{{ form.bedroom }} 室 {{ form.office }} 厅</div></template>
      <template slot="rentalArea"><div>{{ form.rentalArea }} m²</div></template>
      <template slot="expectPrice"><div>{{ form.expectPrice }} 元/月</div></template>
      <template slot="createTime"><div>{{ form.createTime }}</div></template>
      <template slot="isOwnerSignState"><div>{{ form.isOwnerSignState === 1 ? '未签订' : form.isOwnerSignState === 2 ? '已签订' : '/' }}</div></template>
      <template slot="isTenantSignState"><div>{{ form.isTenantSignState === 1 ? '未签订' : form.isTenantSignState === 2 ? '已签订' : '/' }}</div></template>
    </avue-form>
    <el-card v-else>
      <div class="flex align-items justify-content-center marginBottom20">
        <el-button type="text" @click="handleViewContract">展示居间合同内容，点击可查看预览</el-button>
      </div>
      <div v-if="form.intermediaryState === 2" class="flex align-items justify-content-center">
        <el-button type="primary" @click="handleExamine">审核</el-button>
      </div>
      <el-descriptions title="审核驳回" :column="1" v-if="form.intermediaryState === 4">
        <el-descriptions-item label="驳回原因">{{ form.intermediaryStateReason }}</el-descriptions-item>
      </el-descriptions>
    </el-card>

    <Examine v-if="showExamine" :show="showExamine" :request="examineRequest" :examineId="examineId" @close="handleExamineClose"/>
  </div>
</template>

<script>
import {contractView, intermediaryRentExamine} from "@/api/contract/customer";
import Examine from "../second/examine";
export default {
  name: "nodeOne",
  components: {Examine},
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 170,
        span: 8,
        disabled: false,
        column: [
          {
            label: '业主',
            prop: 'partyAName'
          },
          {
            label: '租户',
            prop: 'partyBName'
          },
          {
            label: '经纪人',
            prop: 'brokerName'
          },
          {
            label: '房源名称',
            prop: 'houseName'
          },
          {
            label: '房源号',
            prop: 'houseCode'
          },
          {
            label: '业务类型',
            prop: 'businessType'
          },
          {
            label: '楼盘名称',
            prop: 'county'
          },
          {
            label: '出租方式',
            prop: 'rentalMode'
          },
          {
            label: '户型',
            prop: 'bedroom'
          },
          {
            label: '出租面积',
            prop: 'rentalArea'
          },
          {
            label: '期望租金',
            prop: 'expectPrice'
          },
          {
            label: '发起时间',
            prop: 'createTime'
          },
          {
            label: '业主签订状态',
            prop: 'isOwnerSignState'
          },
          {
            label: '租户签订状态',
            prop: 'isTenantSignState'
          }
        ]
      },

      showExamine: false,
      examineRequest: intermediaryRentExamine,
      examineId: ''
    }
  },
  methods: {
    setForm(form){
      // console.log(form)
      this.form = form
      this.loading = false;
    },
    // 查看居间合同
    handleViewContract(){
      if(!this.form.intermediaryId){
        this.$message.warning('请先在app端中完成内容填写!')
        return
      }
      contractView({
        id: this.form.intermediaryId
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          window.open(res.data.pageUrl, '_blank')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 审核
    handleExamine(){
      this.showExamine = true
      this.examineId = this.form.id
    },
    handleExamineClose(){
      this.showExamine = false
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>