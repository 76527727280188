<template>
  <div>
  <avue-form ref="form"
             v-if="form.businessState === 3"
             v-model="form"
             v-loading="loading"
             :option="formOption">
    <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
    <template slot="partyBName"><div>{{ form.partyBName }}</div></template>
    <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
    <template slot="isLeaseOwnerSignState"><div>{{ form.isLeaseOwnerSignState === 1 ? '未签订' : '已签订' }}</div></template>
    <template slot="isLeaseTenantSignState"><div>{{ form.isLeaseTenantSignState === 1 ? '未签订' : '已签订' }}</div></template>
    <template slot="monthRentPrice"><div>{{ form.monthRentPrice }}</div></template>
  </avue-form>

    <el-card v-else>
      <div class="flex align-items justify-content-center marginBottom20">
        <el-button type="text" @click="handleViewContract">展示租赁合同内容，点击可查看预览</el-button>
      </div>
      <div v-if="form.businessState === 2" class="flex align-items justify-content-center">
        <el-button type="primary" @click="handleExamine">审核</el-button>
      </div>
      <el-descriptions title="审核驳回" :column="1" v-if="form.businessState === 4">
        <el-descriptions-item label="驳回原因">{{ form.businessStateReason }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <ExamineThree v-if="showExamine" :show="showExamine" :request="examineRequest" :examineId="examineId" @close="handleExamineClose"/>
  </div>
</template>

<script>
import {contractView, transactRentExamine} from "@/api/contract/customer";
import ExamineThree from "../second/examineThree";
export default {
  name: "nodeTwo",
  components: {ExamineThree},
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 170,
        span: 8,
        disabled: false,
        column: [
          {
            label: '业主',
            prop: 'partyAName'
          },
          {
            label: '租户',
            prop: 'partyBName'
          },
          {
            label: '经纪人',
            prop: 'brokerName'
          },
          {
            label: '业主签订状态',
            prop: 'isLeaseOwnerSignState'
          },
          {
            label: '租户签订状态',
            prop: 'isLeaseTenantSignState'
          },
          {
            label: '月租金',
            prop: 'monthRentPrice',
            span: 24
          }
        ]
      },

      showExamine: false,
      examineRequest: transactRentExamine,
      examineId: ''
    }
  },
  methods: {
    setForm(form){
      // console.log(form)
      this.form = form
      this.loading = false;
    },
    handleViewContract(){
      if(!this.form.transactId){
        this.$message.warning('请先在app端中完成内容填写!')
        return
      }
      contractView({
        id: this.form.transactId
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          window.open(res.data.pageUrl, '_blank')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 审核
    handleExamine(){
      this.showExamine = true
      this.examineId = this.form.id
    },
    handleExamineClose(){
      this.showExamine = false
      console.log('关闭审核')
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>