<template>
  <avue-form ref="form"
             v-model="form"
             v-loading="loading"
             :option="formOption"
             @submit="handleSubmit">
  </avue-form>
</template>

<script>
import {rentFour} from "@/api/contract/customer";

export default {
  name: "nodeFour",
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitText: '确认并进入下一节点',
        submitBtn: true,
        emptyBtn: false,
        labelWidth: 300,
        span: 12,
        disabled: false,
        column: [
          {
            label: '确认押金及首笔租金已按合同约定支付',
            prop: 'isConfirmDeposit',
            type: 'checkbox',
            dicData: [
              { label: '确认完成', value: 1 }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "选择确认押金及首笔租金已按合同约定支付",
              trigger: "blur"
            }]
          },
          {
            label: '说明',
            prop: 'confirmDepositDesc',
            type: 'textarea',
            maxlength: 100,
            showWordLimit: true,
            rules: [{
              required: true,
              message: "请输入说明",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  mounted() {
    if(this.$route.query.type === 'view'){
      this.formOption.disabled = true;
      this.formOption.submitBtn = false;
    }
  },
  methods: {
    setForm(form){
      // console.log(form)
      const { isConfirmDeposit, ...rest } = form;
      this.form = {
        isConfirmDeposit: isConfirmDeposit === 1 ? [1] : [],
        ...rest
      }
      if(this.$route.query.type === 'edit') {
        this.formOption.disabled = form.nodeFour === 2 || form.agentState === 2;
        this.formOption.submitBtn = form.nodeFour !== 2 && form.agentState !== 2;
      }
      this.loading = false;
    },
    handleSubmit(form,done){
      // console.log(form)
      rentFour({
        id: form.id,
        isConfirmDeposit: Number(form.isConfirmDeposit.toString()),
        confirmDepositDesc: form.confirmDepositDesc
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('nextStep',{
            currentNode: res.data.currentNode
          })
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    }
  }
}
</script>

<style scoped>

</style>