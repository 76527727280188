<template>
  <div id="nodeThree">
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption"
               :upload-error="uploadError"
               :upload-delete="uploadDelete"
               :upload-after="uploadAfter"
               :upload-before="uploadBefore"
               @submit="handleSubmit">
      <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
      <template slot="partyBName"><div>{{ form.partyBName }}</div></template>
      <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
      <template slot="hr"><hr></template>

      <template slot="ownerPayAmount"><div>￥{{ form.ownerPayAmount }}</div></template>
      <template slot="empty"><div></div></template>
      <template slot="tenantPayAmount"><div>￥{{ form.tenantPayAmount }}</div></template>
    </avue-form>
  </div>
</template>

<script>
import { rentThree } from "@/api/contract/customer"
export default {
  name: "nodeThree",
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitText: '确认并进入下一节点',
        submitBtn: true,
        emptyBtn: false,
        labelWidth: 170,
        span: 8,
        disabled: false,
        column: [
          {
            label: '业主',
            prop: 'partyAName'
          },
          {
            label: '租户',
            prop: 'partyBName'
          },
          {
            label: '经纪人',
            prop: 'brokerName'
          },
          {
            label: '',
            prop: 'hr',
            span: 24,
            row: true,
            labelWidth: 0
          },
          {
            label: '是否完成线下支付',
            prop: 'isCompletePay',
            type: 'checkbox',
            dicData: [
              { label: '确认完成', value: 1 }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "选择是否完成线下支付",
              trigger: "blur"
            }]
          },
          {
            label: '业主支付金额',
            prop: 'ownerPayAmount',
            rules: [{ required: true }]
          },
          {
            label: '业主支付凭证',
            prop: 'ownerPayVoucher',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            fileSize: 20 * 1024,
            limit: 3,
            rules: [{
              required: true,
              message: "请上传业主支付凭证",
              trigger: "blur"
            }]
          },
          {
            label: '',
            prop: 'empty'
          },
          {
            label: '租户支付金额',
            prop: 'tenantPayAmount',
            rules: [{ required: true }]
          },
          {
            label: '租户支付凭证',
            prop: 'tenantPayVoucher',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            fileSize: 20 * 1024,
            limit: 3,
            rules: [{
              required: true,
              message: "请上传租户支付凭证",
              trigger: "blur"
            }]
          }
        ]
      },
      uploadList: [], //上传图片数据列表
    }
  },
  mounted() {
    if(this.$route.query.type === 'view'){
      this.formOption.disabled = true;
      this.formOption.submitBtn = false;
    }
    this.uploadList = this.formOption.column.filter(item => item.type === 'upload'); //获取上传组件
  },
  methods: {
    setForm(form){
      // console.log(form)
      const { isCompletePay, ...rest } = form;
      this.form = {
        isCompletePay: isCompletePay === 1 ? [1] : [],
        ...rest
      }
      if(this.$route.query.type === 'edit') {
        this.formOption.disabled = form.nodeThree === 2;
        this.formOption.submitBtn = form.nodeThree !== 2;
      }
      this.loading = false;
    },
    handleSubmit(form,done){
      // console.log(form)
      rentThree({
        id: form.id,
        isCompletePay: Number(form.isCompletePay.toString()),
        ownerPayVoucher: form.ownerPayVoucher,
        tenantPayVoucher: form.tenantPayVoucher
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('nextStep',{
            currentNode: res.data.currentNode
          })
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          if(action === 'confirm'){
            let array = JSON.parse(JSON.stringify(this.form[column.prop].split(',')));
            let index = array.findIndex(item=>item === file.url)
            array.splice(index,1);
            column.propsHttp.name = array.toString();
            if(array.length < column.limit){
              this.upload('inline-block',column.prop);
            }
          }
          done();
        }
      });
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        if(column.propsHttp.name){
          column.propsHttp.name += ',' + res.path
        }else{
          column.propsHttp.name = res.path;
        }
        if(column.propsHttp.name.split(',').length >= column.limit) {
          this.upload('none',column.prop);
        }
      }
    },
    upload(type,prop){
      let index = this.uploadList.findIndex(item => item.prop === prop)
      let upload = document.getElementById('nodeThree').getElementsByClassName('el-upload')[index];
      upload.style.display = type === 'none' ? 'none' : 'inline-block';
    }
  }
}
</script>

<style scoped>
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
</style>