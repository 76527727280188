<template>
  <avue-form ref="form"
             v-model="form"
             v-loading="loading"
             :option="formOption"
             @submit="handleSubmit">
  </avue-form>
</template>

<script>
import {rentFive} from "@/api/contract/customer";

export default {
  name: "nodeFive",
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitText: '确认并进入下一节点',
        submitBtn: true,
        emptyBtn: false,
        labelWidth: 300,
        span: 12,
        disabled: false,
        column: [
          {
            label: '是否完成验房收房',
            prop: 'isInspectHouse',
            type: 'checkbox',
            dicData: [
              { label: '确认完成', value: 1 }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "选择是否完成验房收房",
              trigger: "blur"
            }]
          },
          {
            label: '说明',
            prop: 'inspectHouseDesc',
            type: 'textarea',
            maxlength: 100,
            showWordLimit: true
          }
        ]
      }
    }
  },
  mounted() {
    if(this.$route.query.type === 'view'){
      this.formOption.disabled = true;
      this.formOption.submitBtn = false;
    }
  },
  methods: {
    setForm(form){
      // console.log(form)
      const { isInspectHouse, ...rest } = form;
      this.form = {
        isInspectHouse: isInspectHouse === 1 ? [1] : [],
        ...rest
      }
      if(this.$route.query.type === 'edit') {
        this.formOption.disabled = form.nodeFive === 2 || form.agentState === 2;
        this.formOption.submitBtn = form.nodeFive !== 2 && form.agentState !== 2;
      }
      this.loading = false;
    },
    handleSubmit(form,done){
      // console.log(form)
      rentFive({
        id: form.id,
        isInspectHouse: Number(form.isInspectHouse.toString()),
        inspectHouseDesc: form.inspectHouseDesc
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('nextStep',{
            currentNode: res.data.currentNode
          })
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    }
  }
}
</script>

<style scoped>

</style>